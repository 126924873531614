import axios from 'axios';
import { setCookie } from 'nookies';

export const serverSideAxios = axios.create({
  baseURL: `${process.env.API_ENDPOINT}/api`,
});

export const getPhotoSource = (
  photoId?: string,
  defaultSource?: string,
  withHost = false,
): string => {
  if (!photoId || typeof photoId !== 'string') {
    return defaultSource || `${withHost ? 'https://rekee.ru' : ''}/EmptyImage.png`;
  }

  return `${withHost ? 'https://rekee.ru' : ''}/api/photo/${photoId}${
    photoId.includes('.webp') ? '' : '.webp'
  }`;
};

export const getVideoSource = (videoId: string): string => {
  if (!videoId) {
    return `/api/video/test-video`;
  }

  return `/api/video/${videoId.includes('.mp4') ? videoId : `${videoId}.mp4`}`;
};

const authAxios = axios.create({
  baseURL: `/api`,
});

authAxios.interceptors.response.use(
  response => response,
  async error => {
    const errorStatus = error?.response?.status ?? null;

    if (errorStatus === 401) {
      try {
        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');

          const { data } = await authAxios.post('/auth/refresh', {
            refreshToken,
          });

          const { token, refreshToken: receivedRefreshToken } = data;

          localStorage.setItem('token', token);
          setCookie({}, 'token', token, { path: '/' });
          const response = await authAxios.request(error.config);
          localStorage.setItem('refreshToken', receivedRefreshToken);

          return response;
        }
      } catch (e) {
        console.error(e);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      }
    }

    console.error(error);

    return Promise.reject(error);
  },
);

export default authAxios;
