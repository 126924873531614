import '../styles/cropper.css';
import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { useCallback, useEffect, useMemo, useState } from 'react';
import authAxios from '../helpers/axios';
import authContext from '../helpers/authContext';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import { YMInitializer } from 'react-yandex-metrika';
import Script from 'next/script';
import Head from 'next/head';

function MyApp({ Component, pageProps }: AppProps) {
  const [authed, setAuthed] = useState(false);
  // no auth required, temporary set false
  const [checkingAuth, setCheckingAuth] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const router = useRouter();

  const getAccounts = useCallback(async () => {
    /* try {
      const { data } = await authAxios.get('/account');

      if (Array.isArray(data)) {
        if (data.length) {
          const currentAccount = localStorage.getItem('currentAccount');
          if (!currentAccount || !data.some(item => item.username === currentAccount)) {
            localStorage.setItem('currentAccount', data[0].username);
            setCookie({}, 'account', data[0].username, { path: '/' });
          }
        } else {
          router.replace('/account/create');
        }

        setAccounts(data);
        setAuthed(true);
      }
    } catch (e) {
      console.log(e);
    }
    setCheckingAuth(false); */
  }, []);

  useEffect(() => {
    getAccounts();
  }, []);

  const authContextValue = useMemo(
    () => ({
      checkingAuth,
      authed,
      setAuthed,
      accounts,
      getAccounts,
      login: async (token = '', refreshToken = '') => {
        if (token && refreshToken) {
          localStorage.setItem('token', token);
          setCookie({}, 'token', token, { path: '/' });
          localStorage.setItem('refreshToken', refreshToken);
        }
        await getAccounts();
      },
      signOut: async () => {
        await authAxios.post('/signOut');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        setAuthed(false);
        setAccounts([]);
      },
    }),
    [authed, accounts, checkingAuth],
  );

  return (
    <authContext.Provider value={authContextValue}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-8JE3WF718W" />
      <Script id="gtag">
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "G-8JE3WF718W");`}
      </Script>
      <YMInitializer accounts={[87920653]} />
      <Component {...pageProps} />
    </authContext.Provider>
  );
}

export default MyApp;
