import { createContext } from 'react';

const authContext = createContext({
  checkingAuth: true,
  authed: false,
  setAuthed: (a: boolean) => console.log('auth', a),
  accounts: [],
  getAccounts: async () => {},
  login: async (a = '', b = '') => console.log('login', a, b),
  signOut: async () => {},
});

export default authContext;
